import React, { useContext } from "react";
import styled from "styled-components";
import Card from "../../../../Components/Card";
import CardTitle from "../../../../Components/CardTitle";
import TransactionContext from "../../../../Contexts/Transaction";

const History = () => {
  const checkHistory = useContext(TransactionContext);
  return (
    <Card>
      <CardTitle>History</CardTitle>
      <StyledWrapper>
        <StyledItems>
          {[...checkHistory.exchangeidlist]
            .reverse()
            .map((transactionitem, index) => {
              return (
                <StyledItem key={index}>
                  <span>{index + 1}</span>
                  <span onClick={checkHistory.HandleHistory}>
                    {transactionitem.exchangeitem}
                  </span>
                </StyledItem>
              );
            })}
        </StyledItems>
      </StyledWrapper>
    </Card>
  );
};

const StyledWrapper = styled.div`
  padding: 15px 5px;
  color: ${(props) => props.theme.color.ColorTwo};
`;
const StyledItems = styled.ul`
  max-height: calc(70vh - 260px);
  height: 180px;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #b4c9f2;
    border-radius: 8px;
    border: 1px solid transparent;
  }
`;
const StyledItem = styled.li`
  display: flex;
  display: -ms-flexbox;
  justify-content: left;
  padding: 10px;
  cursor: pointer;
  &:hover {
    span {
      &:nth-child(1) {
        color: ${(props) => props.theme.color.ColorFive};
      }
      &:nth-child(2) {
        color: #f39c12;
      }
    }
  }
  span {
    &:nth-child(1) {
      color: #f39c12;
      margin-right: 13px;
    }
    &:nth-child(2) {
      color: ${(props) => props.theme.color.ColorFive};
    }
  }
`;
export default History;
